import ReviewIllustration from '../assets/images/review-illustration.png'
import StarIcon from '../components/StarIcon'
import WheelIcon from '../components/WheelIcon'
import ArrowIcon from '../components/ArrowIcon'
import { useEffect, useState } from 'react'
import { FamewallReviews } from "./FamewallReviews"
import { usePaymentStore } from '../stores/payment'

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type Props = {
  onProfileReviewClick?: VoidFunction
  onPhotoReviewClick?: VoidFunction
}

export const ProfileReviewLanding = ({ onProfileReviewClick = undefined, onPhotoReviewClick = undefined }: Props) => {
  const { openPaymentModal } = usePaymentStore()

  const getInitialCount = () => parseInt(localStorage.getItem("reviewsGenerated") ?? "0") || getRandomNumber(240, 300)
  const [profileReviewsCount, setProfileReviewsCount] = useState(getInitialCount);

  useEffect(() => {
    if (profileReviewsCount)
      localStorage.setItem("reviewsGenerated", profileReviewsCount.toString())
  }, [profileReviewsCount])

  useEffect(() => {
    const interval = setInterval(() => {
      setProfileReviewsCount((prevCount) => {
        const shouldStaySame = Math.random() < 0.3; // 30% chance to stay the same
        if (shouldStaySame) {
          return prevCount;
        }
        const newNumber = Math.min(prevCount + getRandomNumber(0, 5), 300);
        return newNumber;
      });
    }, 5000); // Update every second

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="mx-auto max-w-xl pb-4">
        <div className="flex flex-col flex-1">
          <h1 className="text-[32px] font-bold mt-8 self-center">AI Profile Reviews</h1>
          <img src={ReviewIllustration} alt="profile" className="w-auto h-full mt-4" />
          <p className="my-3 text-3xl font-bold">AI Profile Reviews</p>
          <p className="text-base pb-4">Your profile is your resume. Optimize your profile with the #1 AI dating assistant and start getting up to 2.5x more matches</p>

          <div className='mt-3 border-2 border-black rounded-xl p-4 bg-white cursor-pointer' onClick={onProfileReviewClick}>
            <div className='flex flex-1 justify-between'>
              <div className='flex items-center'>
                <p className='font-bold text-brand-primary'>Full Profile Review</p>
                <div className="ml-1"><ArrowIcon /></div>
              </div>
              <StarIcon />
            </div>
            <p className='text-sm'>End to end review of your whole profile</p>
          </div>
          <div className='mt-3 border-2 border-black rounded-xl p-4 bg-white cursor-pointer' onClick={onPhotoReviewClick}>
            <div className='flex flex-1 justify-between'>
              <div className='flex items-center'>
                <p className='font-bold text-brand-primary'>Photo Review</p>
                <div className="ml-1"><ArrowIcon /></div>
              </div>
              <WheelIcon />
            </div>
            <p className='text-sm'>Find your best photos</p>
          </div>
          <span className='self-center mt-4 text-sm font-normal'>
            <span className='text-brand-primary text-base font-semibold'>
              {`${profileReviewsCount} profiles`}
            </span> reviewed in the last 24h</span>
        </div>
      </div>
      <FamewallReviews
        containerStyle='-mx-4'
        title='150,000+ happy users'
      />
    </>
  )
}