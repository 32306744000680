export const MONTHLY_PRICE = 14
export const ANNUAL_MONTHLY_PRICE = 5
export const ANNUAL_PRICE = 60

export const SALE_MONTHLY_PRICE = 23
export const SALE_ANNUAL_MONTHLY_PRICE = 8
export const SALE_ANNUAL_PRICE = 100

export const AIPHOTOS_SUBSCRIBED = 15
export const AIPHOTOS_UNSUBSCRIBED = 29

export const SALE_PERCENTAGE = 40
