import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import {
  WizardStep as WizardStepModel,
  WizardStepInputType,
  WizardStepType,
} from "../../models/wizard";
import { getStep, getStepIndex } from "../../utils";
import { FileUploadStep } from "./FileUploadStep";
import LockIcon from "../LockIcon";
import { usePaymentStore } from "../../stores/payment";

interface Props {
  name: string;
  wizardStep: WizardStepModel;
  goToNextStep: () => void;
  steps: Array<WizardStepModel>;
  step: WizardStepType;
  hasPaid?: boolean;
  email?: string;
  setStep: (step: WizardStepType) => void;
  stepResults: Record<string, string>;
  setStepResult: (stepType: string, result: string) => void;
  onBackPress?: VoidFunction
  setEmail?: (email: string) => void
}
export const WizardStep = ({
  name,
  wizardStep,
  goToNextStep,
  steps,
  step,
  hasPaid = false,
  email = undefined,
  setStep,
  stepResults,
  setStepResult,
  setEmail = undefined,
  onBackPress = undefined
}: Props) => {
  const { setOpenPaymentModal, setProduct } = usePaymentStore()

  const getStepValue = () => {
    return stepResults[wizardStep.step];
  };

  const getFirstStep = () => !!!steps.findIndex(
    (wizardStep: WizardStepModel) => wizardStep.step === step
  )

  const [isFirstStep, setIsFirstStep] = useState(getFirstStep);

  return (
    <div className="mt-6 mb-20">
      <>
        {(!isFirstStep || (name === "profileReviewer" || name === "photoReview")) && (
          <svg
            onClick={() => {
              const currentStep = getStepIndex(step, steps);

              if (currentStep === 0) {
                localStorage.removeItem(`${name}:step`);
                onBackPress?.();
                return;
              }

              let previousStep = getStep(step, -1, steps);

              // Check if the previous step is the email step and the user is already authenticated
              if (previousStep.step === "email" && auth.currentUser && auth.currentUser.email) {
                // Attempt to go back one more step if email is the previous step
                previousStep = getStep(previousStep.step, -1, steps);

                // If there's no previous step before email, handle it like going back from the 0th step
                if (!previousStep) {
                  localStorage.removeItem(`${name}:step`);
                  onBackPress?.();
                  return;
                }
              }

              // Set the step to the previous step and store it in localStorage
              setStep(previousStep.step);
              localStorage.setItem(`${name}:step`, previousStep.step);
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            className="w-12 h-12 stroke-zinc-400 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        )}
        <div className={`px-2 ${isFirstStep ? "mt-10" : "mt-6"}`}>
          <h1 className="text-4xl font-bold">{wizardStep.label}</h1>
          {wizardStep.caption && (
            <h1 className="text-xl text-gray-600 mt-2">{wizardStep.caption}</h1>
          )}
          <div className="">
            {wizardStep.type === WizardStepInputType.CONTENT && (
              <>{wizardStep.content}</>
            )}

            {wizardStep.type === WizardStepInputType.SPACER && (
              <>{wizardStep.content}</>
            )}

            {wizardStep.type === WizardStepInputType.TEXT && (
              <div className="mt-4">
                <textarea
                  value={getStepValue()}
                  placeholder={wizardStep.placeholder}
                  onChange={(e) => setStepResult(wizardStep.step, e.target.value)}
                  className="bg-transparent text-2xl w-full outline-none text-zinc-500"
                  rows={100}
                />
              </div>
            )}

            {wizardStep.type === WizardStepInputType.EMAIL && (
              <div className="mt-4">
                <input
                  type="email"
                  value={email}
                  placeholder={wizardStep.placeholder}
                  onChange={(e) => {
                    setEmail?.(e.target?.value?.toLowerCase())
                  }}
                  className="bg-transparent text-2xl w-full outline-none text-zinc-500"
                />
              </div>
            )}

            {wizardStep.type === WizardStepInputType.RADIO &&
              wizardStep.choices && (
                <div className="flex flex-wrap">
                  {wizardStep.choices.map((choice: string) => {
                    return (
                      <div
                        key={choice}
                        className={`${getStepValue() === choice
                          ? "border-black border-2 pl-5 pr-3 hover:bg-brand-primary hover:text-white transition duration-300 ease-in-out"
                          : "border-black px-10 hover:bg-black hover:text-white transition duration-300 ease-in-out"
                          } bg-white border py-2 mr-4 rounded-full mt-5 cursor-pointer flex stroke-brand-primary hover:stroke-white`}
                        onClick={() => {
                          setStepResult(wizardStep.step, choice);
                          goToNextStep();
                        }}
                      >
                        {choice}

                        {getStepValue() === choice && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            className={`w-6 h-6 ml-2`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {wizardStep.type === WizardStepInputType.RADIO_VERTICAL &&
                wizardStep.choices && (
                  <div className="flex flex-col w-full mt-3"> {/* Added mt-6 for more space */}
                    {wizardStep.choices.map((choice: string) => {
                      const isSelected = getStepValue() === choice;
                      return (
                        <div
                          key={choice}
                          className={`${isSelected
                            ? "border-black border-2 bg-black"
                            : "border-black hover:bg-black hover:text-white"
                            } bg-white border py-3 px-5 mt-4 rounded-full cursor-pointer transition duration-300 ease-in-out flex justify-between items-center w-full`}
                          onClick={() => {
                            setStepResult(wizardStep.step, choice);
                            goToNextStep();
                          }}
                        >
                          <span className={isSelected ? "text-black" : ""}>{choice}</span>

                          {isSelected && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              className="w-6 h-6 ml-2 stroke-white"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}

          {wizardStep.type === WizardStepInputType.MULTI_SELECT &&
              wizardStep.choices && (
                <div className="flex flex-wrap">
                  {wizardStep.choices.map((choice: string) => {
                    const currentSelections = getStepValue() ? getStepValue().split(',') : [];
                    const isSelected = currentSelections.includes(choice);
                    return (
                      <div
                        key={choice}
                        className={`${isSelected
                          ? "border-black border-2"
                          : "border-black"
                        } bg-white border py-2 px-5 mr-4 rounded-full mt-5 cursor-pointer flex items-center justify-between hover:bg-gray-100 transition duration-300 ease-in-out`}
                        onClick={() => {
                          let newSelections;
                          if (isSelected) {
                            newSelections = currentSelections.filter(item => item !== choice);
                          } else {
                            newSelections = [...currentSelections, choice];
                          }
                          setStepResult(wizardStep.step, newSelections.join(','));
                        }}
                      >
                        <span className="mr-2">{choice}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke={isSelected ? "currentColor" : "#d1d5db"}
                          className={`w-6 h-6 ${isSelected ? "text-brand-primary" : "text-gray-300"}`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                    );
                  })}
                </div>
              )}

            {wizardStep.type === WizardStepInputType.SELECT &&
              wizardStep.choices && (
                <div>
                  {wizardStep.choices.map((choice: string) => {
                    return (
                      <div
                        key={choice}
                        className={`${getStepValue() === choice
                          ? "bg-brand-primary text-white"
                          : "border-black border-2 bg-white transition duration-300 ease-in-out"
                          } border py-2 mr-4 rounded-md mt-5 cursor-pointer flex hover:bg-black hover:text-white px-5`}
                        onClick={() => {
                          setStepResult(wizardStep.step, choice);
                          goToNextStep();
                        }}
                      >
                        <div className="w-3/4">{choice}</div>

                        {getStepValue() === choice && (
                          <div className="flex flex-row-reverse w-1/4 items-center justify-start">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 ml-2 stroke-white"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {wizardStep.lockedChoices && wizardStep.lockedChoices.map((choice: string) => {
                    return (
                      <div
                        key={choice}
                        className={`group ${getStepValue() === choice
                          ? "bg-brand-primary text-white"
                          : "border-black border-2 bg-white transition duration-300 ease-in-out"
                          } border py-2 mr-4 rounded-md mt-5 cursor-pointer flex hover:bg-black hover:text-white px-5`}
                        onClick={() => {
                          if (!hasPaid) {
                            setProduct("profile_writer")
                            setOpenPaymentModal(true)
                          } else {
                            setStepResult(wizardStep.step, choice);
                            goToNextStep();
                          }
                        }}
                      >
                        <div className="w-3/4">{choice}</div>
                        <div className="flex flex-row-reverse w-1/4 items-center justify-start">
                          {!hasPaid && (
                            <LockIcon
                              className="text-black group-hover:text-white" // Apply hover color change here
                            />)}
                          {getStepValue() === choice && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 ml-2 stroke-white"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

            {wizardStep.type === WizardStepInputType.FILE &&
              wizardStep.onFilesUploaded && (
                <FileUploadStep
                  alreadySetFiles={
                    getStepValue() ? JSON.parse(getStepValue()) : []
                  }
                  onFilesUploaded={(files: Array<string>) => {
                    // Set the step result
                    setStepResult(wizardStep.step, JSON.stringify(files));

                    // Do the wizard step handler
                    wizardStep.onFilesUploaded(files);
                  }}
                />
              )}
          </div>
        </div>
      </>
    </div>
  );
};
