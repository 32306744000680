import ChatIllustration from '../../assets/images/premium/chat_prem.webp'
import ReviewIllustration from '../../assets/images/premium/review_prem.webp'
import WriterIllustration from '../../assets/images/premium/writer_prem.webp'
import AIPhotosIllustration from '../../assets/images/premium/aiphotos_prem.webp'

export interface PremiumDataItem {
  key: string;
  heading: string;
  image: string;
  title: string;
  description: string;
}


export const premiumData: PremiumDataItem[] = [
  {
    key: "chat_assistant",
    heading: "Texting",
    title: "Put texting on cruise control",
    description: "Craft witty replies, or flirty openers for any profile",
    image: ChatIllustration,
  },
  {
    key: "profile_review",
    heading: "Profile Review",
    title: "Enhance your profile",
    description: "Rate your profile and choose your best photos",
    image: ReviewIllustration,
  },
  {
    key: "profile_writer",
    heading: "Bio Writer",
    title: "Get more matches with perfect bio",
    description: "Writer takes your best features and spins it into a dating bio guaranteed to catch attention",
    image: WriterIllustration,
  },
  {
    key: "photos",
    heading: "Photos",
    title: "AI enhanced photos",
    description: "Get 50% off our AI photo package and double your swipes with a more charming you.",
    image: AIPhotosIllustration,
  },
];