import { ProductType } from '../models/payment';
import { PlanType } from './../constants/payments';
import { create } from "zustand";

interface PaymentStore {
  openPaymentModal: boolean;
  setOpenPaymentModal: (openPaymentModal: boolean) => void;

  planType: PlanType | undefined,
  setPlanType: (planType: PlanType | undefined) => void;

  product: ProductType,
  setProduct: (product: ProductType) => void;
}

export const usePaymentStore = create<PaymentStore>((set) => ({
  openPaymentModal: false,
  setOpenPaymentModal: (openPaymentModal: boolean) => set({ openPaymentModal }),

  planType: undefined,
  setPlanType: (planType: PlanType | undefined) => set({ planType }),

  product: "chat_assistant",
  setProduct: (product: ProductType) => set({ product }),
}));
