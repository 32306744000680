type Props = {
  title: string
  description: string
  illustration: string | undefined
}

export const PremiumProductStep = ({ title, description, illustration }: Props) => (
  <div className="w-full mt-0 px-4 pt-1 flex flex-col items-center">
    <div className="text-center w-full max-w-sm">
      <p className="text-[22px] font-bold text-black mb-2">{title}</p>
      <p className="text-black mb-8 text-base leading-relaxed whitespace-normal">{description}</p>
    </div>
    <img
      src={illustration}
      alt="Premium content"
      className="w-full h-auto object-contain aspect-squarer"
    />
  </div>
);