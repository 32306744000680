import {
  WizardStep,
  WizardStepInputType,
  WizardStepType,
} from "../models/wizard";
import * as yup from "yup";
import { useWizardStore } from "../stores/wizard";
import { ProfileWriterPaywall } from "../components/payment/paywalls/ProfileWriterPaywall";
import reviewGraphic from "../assets/images/review-graphic-mid.webp";
import likesGraphic from "../assets/images/graph_likes.webp";
import { Chart } from 'react-google-charts';
import HingeLogo from '../assets/images/logos/hinge.svg';
import BumbleLogo from '../assets/images/logos/bumble.svg';
import TinderLogo from '../assets/images/logos/tinder.svg';
import BadooLogo from '../assets/images/logos/badoo.svg';
import OkCupidLogo from '../assets/images/logos/okcupid.svg';
import MatchLogo from '../assets/images/logos/match.svg';
import { useEffect } from "react";


const FamewallEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.famewall.io/singleFrame.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div 
      className="famewall-share-embed" 
      data-src="yourmove" 
      data-uuid="7e1a4254-695d-4035-b74b-d58fd290818f" 
      style={{ width: '100%', height: '100%' }}
    />
  );
};

const AttractivenessChart = () => {
  const data = [
    ['Profile Attractiveness', 'Match %', { role: 'style' }, { role: 'annotation' }],
    ['1', 0, 'color: #A9A9A9', '0'],  // Bars for 1-4 with value 0
    ['2', 0, 'color: #A9A9A9', '0'],
    ['3', 0, 'color: #A9A9A9', '0'],
    ['4', 0, 'color: #A9A9A9', '0'],
    ['5', 1, 'color: #A9A9A9', '1'],
    ['6', 2, 'color: #A9A9A9', '2'],
    ['7', 4, 'color: #A9A9A9', '4'],
    ['8', 9, 'color: #A9A9A9', '9'],
    ['9', 22, 'color: #A9A9A9', '22'],
    ['10', 38, 'color: #FF5733', '38'],  // Highlighted bar for 10
  ];


  const options = {
    chart: {
      subtitle: 'Avg # of matches per day',
    },
    bars: 'vertical',
    legend: { position: 'none' },
    vAxis: {
      title: 'Match %',
      textStyle: { fontStyle: 'normal' }, // Ensure text is not italic
      viewWindow: {
        min: 0,  // Ensure the chart starts at 0
        max: 40, // Set a maximum that fits the data to reduce space above the bars
      },
      gridlines: {
        count: 4,  // Adjust the number of gridlines to reduce empty space
      },
    },
    hAxis: {
      title: 'Profile Attractiveness',
      textStyle: { fontStyle: 'normal', fontSize: 10 }, // Ensure text is not italic and reduce font size
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 10,  // Slightly smaller font for annotations to fit within 200px
        color: '#000',
        fontStyle: 'normal', // Ensure annotations are not italic
      },
      stem: {
        color: 'none',
      },
    },
    backgroundColor: 'transparent', // Remove white background
    chartArea: {
      width: '90%',  // Adjust the chart width
      height: '70%', // Reduce the chart area height to fit within 200px total
      top: 10,       // Reduce the top margin
      bottom: 30,    // Ensure there is enough space for the X-axis
    },
    bar: { groupWidth: '70%' }, // Make the bars a bit narrower
  };

  return (
    <div style={{ height: '200px', width: '100%' }}>  {/* Restrict the container height to 200px */}
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="100%" // Ensure the chart fits within the container
        data={data}
        options={options}
      />
    </div>
  );
};


export default AttractivenessChart;



// Define a list of all of the Wizard Steps to be rendered dynamic
export const PROFILE_WRITER_WIZARD_STEPS: Array<WizardStep> = [
  {
    step: WizardStepType.WELCOME,
    type: WizardStepInputType.CONTENT,
    content: (
      <>
        <h1 className="text-5xl font-bold">Welcome to Profile Writer</h1>
        <div className="mt-8">
          <p className="text-2xl">
            Answer a few questions. Get a free, ai-generated dating app profile.
          </p>
        </div>
        <div className="mt-6">
          <p className="text-2xl">
            Tested and optimized for maximum success ✨
          </p>
        </div>
      </>
    ),
  },
  {
    step: WizardStepType.PROFILE_TYPE,
    type: WizardStepInputType.SELECT,
    label: "Which app profile should we create for you?",
    choices: ["Bumble", "Hinge", "Tinder", "Coffee Meets Bagel"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.GENDER,
    type: WizardStepInputType.RADIO,
    label: "You are a ",
    choices: ["Man", "Woman", "Other"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.LOOKING_FOR,
    type: WizardStepInputType.RADIO,
    label: "What are you looking for? ",
    choices: ["something casual", "long term partner", "not sure yet"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.ACTIVITIES,
    type: WizardStepInputType.TEXT,
    label: "What activities do you enjoy in your free time?",
    placeholder: "Surfing",
    validator: yup.string(),
  },
  {
    step: WizardStepType.CHARACTERISTICS,
    type: WizardStepInputType.TEXT,
    label: "What characteristics are you looking for in your partner?",
    placeholder: "Adventurous, witty and kind",
    validator: yup.string(),
  },
  {
    step: WizardStepType.UNUSUAL_SKILL,
    type: WizardStepInputType.TEXT,
    label: "What unusual skill do you have?",
    placeholder: "Knowing the best spot for brunch",
    validator: yup.string(),
  },
  {
    step: WizardStepType.BUCKET_LIST,
    type: WizardStepInputType.TEXT,
    label: "What's one thing on your bucket list?",
    placeholder: "Climb Mt. Everest",
    validator: yup.string(),
  },
  {
    step: WizardStepType.TALKING_ABOUT,
    type: WizardStepInputType.TEXT,
    label: "What do you enjoy talking about?",
    placeholder: "Old school sitcoms",
    validator: yup.string(),
  },
  {
    step: WizardStepType.WORK_AS,
    type: WizardStepInputType.TEXT,
    label: "What do you do for work?",
    placeholder: "Build apps",
    validator: yup.string(),
  },
  {
    step: WizardStepType.EMAIL,
    type: WizardStepInputType.EMAIL,
    label: "What's your email?",
    placeholder: "your.email@gmail.com",
    validator: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
  },
  {
    step: WizardStepType.WRITING_STYLE,
    type: WizardStepInputType.SELECT,
    label: "Pick your writing style",
    choices: ["Flirty"],
    lockedChoices: ["Thoughtful", "Feisty"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.PAYWALL,
    type: WizardStepInputType.CONTENT,
    content: <ProfileWriterPaywall />,
  },
];

export const PROFILE_REVIEWER_WIZARD_STEPS: Array<WizardStep> = [
  {
    step: WizardStepType.GENDER,
    type: WizardStepInputType.RADIO,
    label: "You are a ",
    choices: ["Man", "Woman", "Other"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.GENDER_OTHER,
    type: WizardStepInputType.RADIO,
    label: "Interested in ...",
    choices: ["Women", "Men", "Both"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.LOOKING_FOR,
    type: WizardStepInputType.RADIO,
    label: "Looking for",
    choices: ["something casual", "long term partner", "not sure yet"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.SPACER,
    type: WizardStepInputType.SPACER,
    content: (
      <>
        <h1 className="text-4xl font-bold">Almost there</h1>
        <div className="mt-8">
          <p className="text-2xl">
            Open your app of choice and take screenshots of your dating profile.
          </p>
        </div>
      </>
    ),
  },
  {
    step: WizardStepType.UPLOAD_PHOTO,
    type: WizardStepInputType.FILE,
    label: "Upload your profile ",
    validator: yup.string(),
    onFilesUploaded: (files: Array<string>) => {
      useWizardStore.getState().setProfileReviewerFiles(files);
    },
  },
  {
    step: WizardStepType.EMAIL,
    type: WizardStepInputType.EMAIL,
    label: "What's your email?",
    placeholder: "your.email@gmail.com",
    validator: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
  },
  // {
  //   step: WizardStepType.PAYWALL,
  //   type: WizardStepInputType.CONTENT,
  //   content: <ProfileReviewPaywall />,
  // },
];



export const AI_PHOTO_REVIEW_WIZARD_STEPS: Array<WizardStep> = [
  {
    step: WizardStepType.GENDER,
    type: WizardStepInputType.RADIO,
    label: "You are a ",
    choices: ["Man", "Woman", "Other"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.GENDER_OTHER,
    type: WizardStepInputType.RADIO,
    label: "Interested in ...",
    choices: ["Women", "Men", "Both"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.LOOKING_FOR,
    type: WizardStepInputType.RADIO,
    label: "Looking for",
    choices: ["something casual", "long term partner", "not sure yet"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.EMAIL,
    type: WizardStepInputType.EMAIL,
    label: "What's your email?",
    placeholder: "your.email@gmail.com",
    validator: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
  }
];

const logos = [
  { src: HingeLogo, alt: 'Hinge', width: 'w-24', height: 'h-8' },
  { src: BumbleLogo, alt: 'Bumble', width: 'w-28', height: 'h-8' },
  { src: TinderLogo, alt: 'Tinder', width: 'w-24', height: 'h-8' },
  { src: BadooLogo, alt: 'Badoo', width: 'w-28', height: 'h-8' },
  { src: OkCupidLogo, alt: 'OkCupid', width: 'w-28', height: 'h-8' },
  { src: MatchLogo, alt: 'Match.com', width: 'w-32', height: 'h-8' },
];

export const WELCOME_PROFILE_REVIEWER_WIZARD_STEPS: Array<WizardStep> = [
  {
    step: WizardStepType.DEFAULT,
    type: WizardStepInputType.RADIO,
    label: "Do you want to get more matches?",
    choices: ["😍 Yes", "😞 No"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.GENDER,
    type: WizardStepInputType.RADIO,
    label: "You are a ",
    choices: ["Man", "Woman", "Other"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.GENDER_OTHER,
    type: WizardStepInputType.RADIO,
    label: "Interested in ...",
    choices: ["Women", "Men", "Both"],
    validator: yup.string(),
  },

  {
    step: WizardStepType.APPS_USED,
    type: WizardStepInputType.MULTI_SELECT,
    label: "Which apps do you use?",
    choices: ["Hinge", "Tinder", "Bumble", "CoffeeMeetsBagel", "Raya", "OkCupid", "Other"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.SPACER,
    type: WizardStepInputType.SPACER,
    content: (
      <div className="flex flex-col items-start">
        <h1 className="text-4xl font-bold mb-6">Let's get started 💪</h1>
        <div className="w-full flex justify-center mb-4">
          <img src={reviewGraphic} alt="Profile Review Graphic" className="w-96 h-auto" />
        </div>
        <div className="mt-4 max-w-2xl">
          <p className="text-xl mb-2">
            Time to optimize your profile. To figure out what's holding you back, we'll start with a few questions 
            about your  <b> swiping habits</b> and <b> dating preferences</b>.
          </p>
        </div>
      </div>
    ),
  },
  {
    step: WizardStepType.LOOKING_FOR,
    type: WizardStepInputType.RADIO,
    label: "What are you looking for?",
    choices: ["something casual", "long term relationship", "soul mate", "still figuring it out"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.STATUS_CURRENT,
    type: WizardStepInputType.RADIO_VERTICAL,
    label: "What is your current dating app experience like?",
    caption: "Depending on your current results - we may need different strategies to hit your target",
    // "How you're performing now will determine the best strategy to get where you want to be."
    choices: ["💔 Not getting matches", "😕 Some matches but they aren't my type", "🤷‍♂️ Enough matches but no dates", "🤩 Everything is great"],
    validator: yup.string(),
  },
  {
    step: WizardStepType.MATCHES_CURRENT,
    type: WizardStepInputType.RADIO_VERTICAL,
    label: "How many matches are you getting?",
    choices: [
      "😕 Less than 1 per day",
      "🙂 1-2 per day",
      "😊 3-7 per day",
      "🤩 8-15 per day",
      "🥵 More than I can handle"
    ],
    validator: yup.string(),
  },
  {
    step: WizardStepType.MATCHES_QUALITY,
    type: WizardStepInputType.RADIO_VERTICAL,
    label: "What about the quality of who you match with?",
    choices: [
      "😑 Not my type at all",
      "🤷‍♂️ A few people I like but rarely",
      "👍 Good. I like a lot of my matches",
      "🥳 Great! Excited about everyone I match"
    ],
    validator: yup.string(),
  },
  
  {
    step: WizardStepType.MATCHES_HAPPINESS,
    type: WizardStepInputType.RADIO_VERTICAL,
    label: "How's your online dating experience going?",
    // "How you're performing now will determine the best strategy to get where you want to be."
    choices: [
      "😕 Not really",
      "🤔 Could be better",
      "😊 Having a good time",
      "🎉 Amazing - wouldn't change anything"
    ],
    validator: yup.string(),
  },

  {
    step: WizardStepType.PROMO_2,
    type: WizardStepInputType.SPACER,
    content: (
      <div className="flex flex-col items-start">
        <h1 className="text-4xl font-bold mb-6">Small changes can have huge results</h1>
        <div className="w-full flex justify-center mb-4">
          <img src={likesGraphic} alt="Matches vs Profile Quality" className="w-96 h-auto" />
        </div>
        <div className="mt-4 max-w-2xl">
          <p className="text-xl mb-2">
            Studies more attractive profiles get exponentially more matches. This means even a small improvement can have massive results.
          </p>
        </div>
      </div>
    ),
  },

  {
    step: WizardStepType.TRIED_SO_FAR,
    type: WizardStepInputType.RADIO,
    label: "What have you tried so far?",
    // "How you're performing now will determine the best strategy to get where you want to be."
    choices: [
      "🤷 Nothing yet",
      "🧪 Boosts",
      "💰 Premium subscriptions",
      "📸 Photographers",
      "👵 Matchmakers",
      "🤯 Literally everything"
    ],
    validator: yup.string(),
  },

  {
    step: WizardStepType.PROMO_3,
    type: WizardStepInputType.SPACER,
    content: (
      <div className="flex flex-col items-start">
        <h1 className="text-4xl font-bold mb-6">Online dating is hard. YourMove can make it easier</h1>
        <div className="w-full flex h-[400px] overflow-hidden">
        {/* <FamewallShare src="yourmove" uuid="7e1a4254-695d-4035-b74b-d58fd290818f" /> */}
        <FamewallEmbed />
        </div>
      </div>
    ),
  },

  {
    step: WizardStepType.MATCHES_TARGET,
    type: WizardStepInputType.RADIO_VERTICAL,
    label: "How many matches do you wish you were getting?",
    caption: "Be ambitious. Life is short.",
    choices: [
      "😊 1-2 matches a week",
      "😊 1-3 matches a day",
      "😎 4-10 matches a day",
      "🤯 10+ matches a day"
    ],
    validator: yup.string(),
  },
  {
    step: WizardStepType.MOTIVATION,
    type: WizardStepInputType.RADIO_VERTICAL,
    label: "How motivated are you to hit that target?",
    caption: "",
    choices: [
      "💪 Very motivated, let's do this!",
      "🙌 I'm quite motivated",
      "🤷 Curious, but not too motivated",
      "😕 Not motivated at all. C'est la vie"
    ],
    validator: yup.string(),
  },

  {
    step: WizardStepType.SPACER_FINAL,
    type: WizardStepInputType.SPACER,
    content: (
      <>
        <h1 className="text-4xl font-bold">...almost there</h1>
        <div className="mt-8">
          <p className="text-2xl">
            Open your app of choice and take screenshots of your profile.
          </p>
        </div>
        <div className="flex flex-col items-center mt-12">
          <p className="text-lg font-semibold">Works for all dating apps</p>
          <div className="mt-4 text-center">
            {logos.map((logo, index) => (
              <div key={index} className="inline-block">
                <img src={logo.src} alt={logo.alt} className={`${logo.width} ${logo.height} object-contain`} />
              </div>
            ))}
          </div>
        </div>
      </>
    ),
  },
  {
    step: WizardStepType.UPLOAD_PHOTO,
    type: WizardStepInputType.FILE,
    label: "Upload your profile screenshots",
    validator: yup.string(),
    onFilesUploaded: (files: Array<string>) => {
      useWizardStore.getState().setProfileReviewerFiles(files);
    },
  },

  {
    step: WizardStepType.EMAIL,
    type: WizardStepInputType.EMAIL,
    label: "...last step",
    caption: "Enter your email to get your profile review and action plan",
    placeholder: "your.email@gmail.com",
    validator: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
  },
  // {
  //   step: WizardStepType.PAYWALL,
  //   type: WizardStepInputType.CONTENT,
  //   content: <ProfileReviewPaywall />,
  // },
];