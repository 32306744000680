type Props = {
  color?: string;
}

export const CrossIcon = ({ color = "white" }: Props) => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4219 20.4531C10.2917 20.3229 10.2057 20.1719 10.1641 20C10.1224 19.8229 10.1224 19.6484 10.1641 19.4766C10.2057 19.3047 10.2891 19.1562 10.4141 19.0312L14.0781 15.3672L10.4141 11.7109C10.2891 11.5859 10.2057 11.4375 10.1641 11.2656C10.1224 11.0938 10.1224 10.9219 10.1641 10.75C10.2057 10.5729 10.2917 10.4193 10.4219 10.2891C10.5469 10.1641 10.6953 10.0807 10.8672 10.0391C11.0443 9.99219 11.2188 9.99219 11.3906 10.0391C11.5677 10.0807 11.7188 10.1615 11.8438 10.2812L15.5 13.9453L19.1641 10.2891C19.2891 10.1641 19.4349 10.0807 19.6016 10.0391C19.7734 9.99219 19.9453 9.99219 20.1172 10.0391C20.2943 10.0807 20.4453 10.1667 20.5703 10.2969C20.7057 10.4219 20.7943 10.5729 20.8359 10.75C20.8776 10.9219 20.8776 11.0938 20.8359 11.2656C20.7943 11.4375 20.7083 11.5859 20.5781 11.7109L16.9297 15.3672L20.5781 19.0312C20.7083 19.1562 20.7943 19.3047 20.8359 19.4766C20.8776 19.6484 20.8776 19.8229 20.8359 20C20.7943 20.1719 20.7057 20.3203 20.5703 20.4453C20.4453 20.5755 20.2943 20.6641 20.1172 20.7109C19.9453 20.7526 19.7734 20.7526 19.6016 20.7109C19.4349 20.6693 19.2891 20.5833 19.1641 20.4531L15.5 16.7969L11.8438 20.4609C11.7188 20.5807 11.5677 20.6641 11.3906 20.7109C11.2188 20.7526 11.0443 20.7526 10.8672 20.7109C10.6953 20.6641 10.5469 20.5781 10.4219 20.4531Z"
      fill={color}
    />
  </svg>
);