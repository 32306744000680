import { useChatStore } from "../../stores/chat";
import { useAuthStore } from "../../stores/auth";
import { useUIStore } from "../../stores/ui";
import { usePaymentStore } from "../../stores/payment";

export const PremiumUpsellPrompt = () => {
  const { chatResponse } = useChatStore();
  const { isSubscribed } = useAuthStore();
  const { hideUpsell, } = useUIStore();
  const { setOpenPaymentModal, setProduct } = usePaymentStore()

  const openPaymentModal = () => {
    setProduct("chat_assistant")
    setOpenPaymentModal(true)
  }

  // only show if there are queries remaining, and they are less than 5 and the user is signed in
  return !isSubscribed && chatResponse?.queriesRemaining !== undefined &&
    chatResponse?.queriesRemaining < chatResponse.queriesAvailable &&
    !hideUpsell ? (
    <div className="w-full text-center mt-4">
      You have {chatResponse.queriesRemaining} / {chatResponse.queriesAvailable}{" "}
      free messages remaining for today.{" "}
      {/* Only show option to upgrade if they are not subscribed */}
      <div
        onClick={openPaymentModal}
        className="font-bold text-brand-primary cursor-pointer"
      >
        Upgrade for unlimited messages, profiles, reviews, and more.
      </div>
    </div>
  ) : null;
};
