import { generateProfileReview } from "../queries";
import { useWizardStore } from "../stores/wizard";

export const useProfileReviewData = () => {
  const {
    profileReviewerFiles,
    email
  } = useWizardStore();

  const fetchReview = async () => {
    try {
      const { data: reviewData } = await generateProfileReview(email, profileReviewerFiles)
      return reviewData
    }
    catch (error) {
      throw error
    };
  }

  return {
    fetchReview
  }
}