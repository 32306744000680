import { Fragment, useRef } from "react";
import { useOutsideAlerter } from "../../utils";
import { Transition } from "@headlessui/react";

interface Props {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  backgroundColor?: string;
  onClose?: VoidFunction;
  fullScreen?: boolean;
}

export const Modal = ({
  children,
  open,
  setOpen,
  backgroundColor,
  onClose,
  fullScreen = false,
}: Props) => {
  const modalRef = useRef(null);
  useOutsideAlerter(modalRef, () => {
    setOpen(false);
    onClose?.();
  });

  return (
    <>
      <Transition
        as={Fragment}
        show={open}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100 rotate-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 rotate-0"
        leaveTo="opacity-0 scale-95 "
      >
        <div className="h-screen z-40 fixed w-full top-0 left-0 backdrop-blur-sm"></div>
      </Transition>
      <Transition
        as={Fragment}
        show={open}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 rotate-0 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 rotate-0 scale-100"
        leaveTo="opacity-0 scale-95 "
      >
        <div
          className={`h-screen w-full left-0 top-0 flex items-center justify-center z-50 fixed ${fullScreen ? "" : "-mt-6"
            }`}
        >
          <div
            ref={modalRef}
            style={{
              backgroundColor: backgroundColor ? backgroundColor : "#f7fafc",
            }}
            className={`relative transform overflow-hidden w-full z-50 text-left shadow-xl transition-all ${fullScreen ? "rounded-none h-full"
              : "max-h-fit rounded-lg sm:my-8 sm:max-w-4xl border-2 border-black px-2 mx-1 sm:p-6 pb-4 pt-5 sm:w-full"
              }`}
          >
            {children}
          </div>
        </div>
      </Transition>
    </>
  );
};